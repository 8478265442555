export const SHOW_SUBPROJECT_DRAWER = "SHOW_SUBPROJECT_DRAWER"

export const toggleSubProjectDrawer = () => (dispatch, getState) => {
    const state = getState()
    const currentState = state.myPage?.subProjects?.showDrawer
    dispatch(show(!currentState))
}

const show = (visible) => ({
    type: SHOW_SUBPROJECT_DRAWER,
    payload: {
        showDrawer: visible,
    },
})
