import { SUBPROJECT_RECEIVE, SUBPROJECT_ERROR, SUBPROJECT_CHANGE_MODE, SUBPROJECT_PRODUCT_SEARCH_QUERY } from "../Actions/SubProject.action"
import { SHOW_SUBPROJECT_DRAWER } from "../Actions/Drawer.action"
import { error as errorReducer } from "./Error.reducer"

const defaultState = {
    list: [],
    mode: "list",
    errors: {},
    showDrawer: false,
    subProject: {},
    query: "",
    searchResult: [],
}

export const subproject = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case SUBPROJECT_PRODUCT_SEARCH_QUERY:
        case SUBPROJECT_RECEIVE:
        case SUBPROJECT_CHANGE_MODE:
            return {
                ...state,
                errors: {},
                ...payload,
            }
        case SUBPROJECT_ERROR:
            return {
                ...state,
                errors: errorReducer(state.errors, action),
            }
        case SHOW_SUBPROJECT_DRAWER:
            return {
                ...state,
                showDrawer: payload.showDrawer,
            }
        default:
            return state
    }
}
