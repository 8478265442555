import React, { Fragment, useEffect, useState } from "react"
import { translate } from "../Services/translation"
import { toggleSubProjectDrawer } from "../Actions/Drawer.action"
import { useDispatch, useSelector } from "react-redux"
import { query as getProjects } from "../Actions/Project.action"
import { query as getSubProjects, addItemToExistingList, addItemToNewList } from "../Actions/SubProject.action"
import Drawer from "./Drawer/Drawer"
import DrawerHeader from "./Drawer/Drawer.header"
import DrawerBody from "./Drawer/Drawer.body"
import DrawerFooter from "./Drawer/Drawer.footer"

const AddToSubProject = ({}) => {
    const showSubProjectDrawer = useSelector((state) => state.myPage.subProjects.showDrawer)
    const projects = useSelector((state) => state.myPage.projects.list)
    const subProjects = useSelector((state) => state.myPage.subProjects.list)
    const errors = useSelector((state) => state.myPage.subProjects.errors)
    const dispatch = useDispatch()

    const [selectedSubProject, setSelectedSubProject] = useState("")
    const [selectedProject, setSelectedProject] = useState("")
    const [newSubProject, setNewSubProject] = useState({})
    const [isCreatingNewList, setIsCreatingNewList] = useState(false)
    const productId = document.getElementById("addToSubProject").getAttribute("data-productid")
    const productName = document.getElementById("addToSubProject").getAttribute("data-productname")
    const qtyInputId = document.getElementById("addToSubProject").getAttribute("data-qtyinputid")
    const disable = document.getElementById("addToSubProject").getAttribute("data-disabled") === "true" ? true : false
    const qty = document.getElementById(qtyInputId)?.value

    useEffect(() => {
        dispatch(getProjects())
    }, [])

    useEffect(() => {
        if (projects && projects.length > 0) {
            setSelectedProject(projects[0].id)
        }
    }, [projects])

    useEffect(() => {
        if (subProjects && subProjects.length === 0) {
            setIsCreatingNewList(true)
        } else {
            setIsCreatingNewList(false)
            setSelectedSubProject(subProjects?.[0]?.id)
        }
    }, [subProjects])

    useEffect(() => {
        if (selectedProject) dispatch(getSubProjects(selectedProject))
    }, [dispatch, selectedProject])

    const handleChange = (propName, value) => {
        setNewSubProject((prevState) => ({
            ...prevState,
            projectId: selectedProject,
            [propName]: value,
        }))
    }

    const handleSubProjectChange = (event) => {
        const value = event.target.value
        setSelectedSubProject(value)
        setIsCreatingNewList(value === "new")
    }

    const handleAddItem = () => {
        let item = {
            id: productId,
            name: productName,
            quantity: qty,
        }
        if (isCreatingNewList) {
            let newList = { ...newSubProject, items: [item] }
            dispatch(addItemToNewList(newList))
        } else {
            dispatch(addItemToExistingList(selectedSubProject, item, true))
        }
    }

    return (
        <>
            <Drawer
                buttonText={translate("subProjectDrawer.button.add")}
                toggleDrawer={() => dispatch(toggleSubProjectDrawer())}
                showDrawer={showSubProjectDrawer}
                disable={disable}
            >
                <Fragment>
                    <DrawerHeader title={translate("subProjectDrawer.title")} onClose={() => dispatch(toggleSubProjectDrawer())} />
                    <DrawerBody>
                        <form>
                            <p>{translate("subProjectDrawer.text")}</p>
                            <label className="form__label" htmlFor="subProjectDrawerProject">
                                {translate("subProjectDrawer.project")}
                            </label>
                            <select
                                className="form__input"
                                id="subProjectDrawerProject"
                                autoComplete="project"
                                value={selectedProject ?? ""}
                                label={translate("subProjectDrawer.project")}
                                onChange={(event) => setSelectedProject(event.target.value)}
                            >
                                {projects?.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.name}
                                    </option>
                                ))}
                            </select>
                            <label className="form__label" htmlFor="subProjectDrawerList">
                                {translate("subProjectDrawer.subProject")}
                            </label>
                            <select
                                className="form__input"
                                id="subProjectDrawerList"
                                autoComplete="subProject"
                                value={selectedSubProject ?? ""}
                                onChange={(event) => handleSubProjectChange(event)}
                            >
                                {subProjects?.map((subProject) => (
                                    <option key={subProject.id} value={subProject.id}>
                                        {subProject.name}
                                    </option>
                                ))}
                                <option value="new">{translate("subProjectDrawer.createNewSubProject")}</option>
                            </select>
                            {isCreatingNewList && (
                                <div>
                                    <div className="h-font-display-xs h-mt-md h-mb-sm">{translate("subProjectDrawer.newSubProject")}</div>
                                    <label className="form__label" htmlFor="subProjectDrawerName">
                                        {translate("mypage.subProject.name")}{" "}
                                    </label>
                                    <input
                                        type="text"
                                        id="subProjectDrawerName"
                                        className="form__input"
                                        placeholder={translate("mypage.subProject.name")}
                                        value={newSubProject.name || ""}
                                        onChange={(event) => handleChange("name", event.target.value)}
                                    />
                                    <label className="form__label" htmlFor="subProjectDrawerDescription">
                                        {translate("mypage.subProject.description")}{" "}
                                    </label>
                                    <textarea
                                        className="form__input"
                                        id="subProjectDrawerDescription"
                                        placeholder={translate("mypage.subProject.description")}
                                        value={newSubProject.description || ""}
                                        onChange={(event) => handleChange("description", event.target.value)}
                                    />
                                </div>
                            )}
                        </form>
                        {errors && errors.status > 0 && (
                            <span className="form__validator--error form__validator--top-narrow">{translate("mypage.subproject.drawer.error")}</span>
                        )}
                    </DrawerBody>
                    <DrawerFooter onClick={() => handleAddItem()} onClose={() => dispatch(toggleSubProjectDrawer())} />
                </Fragment>
            </Drawer>
        </>
    )
}

export default AddToSubProject
