import { PERSON_RECEIVE, PERSON_ERROR, PERSON_CHANGE_MODE, PERMISSION_RECIEVE } from "../Actions/Person.action"
import { ADDRESS_RECEIVE, ADDRESS_ERROR, ADDRESS_CHANGE_MODE } from "../Actions/Address.action"
import { PROJECT_RECEIVE, PROJECT_ERROR, PROJECT_CHANGE_MODE } from "../Actions/Project.action"
import { SUBPROJECT_RECEIVE, SUBPROJECT_ERROR, SUBPROJECT_CHANGE_MODE, SUBPROJECT_PRODUCT_SEARCH_QUERY } from "../Actions/SubProject.action"
import { SHOW_SUBPROJECT_DRAWER } from "../Actions/Drawer.action"
import { person as personReducer } from "./Person.reducer"
import { address as addressReducer } from "./Address.reducer"
import { project as projectReducer } from "./Project.reducer"
import { subproject as subProjectReducer } from "./SubProject.reducer"

const defaultState = {
    persons: {},
    addresses: {},
    projects: {},
    subProjects: {},
    permission: "",
}

export const myPage = (state = defaultState, action) => {
    const { type } = action
    switch (type) {
        case PERMISSION_RECIEVE:
            return {
                ...state,
                permission: action.payload.permission,
            }
        case PERSON_RECEIVE:
        case PERSON_ERROR:
        case PERSON_CHANGE_MODE:
            return {
                ...state,
                persons: personReducer(state.persons, action),
            }
        case ADDRESS_RECEIVE:
        case ADDRESS_ERROR:
        case ADDRESS_CHANGE_MODE:
            return {
                ...state,
                addresses: addressReducer(state.addresses, action),
            }
        case PROJECT_RECEIVE:
        case PROJECT_ERROR:
        case PROJECT_CHANGE_MODE:
            return {
                ...state,
                projects: projectReducer(state.projects, action),
            }
        case SUBPROJECT_RECEIVE:
        case SUBPROJECT_ERROR:
        case SUBPROJECT_CHANGE_MODE:
        case SHOW_SUBPROJECT_DRAWER:
        case SUBPROJECT_PRODUCT_SEARCH_QUERY:
            return {
                ...state,
                subProjects: subProjectReducer(state.subProjects, action),
            }
        default:
            return state
    }
}
