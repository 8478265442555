import { get, post, put, remove as httpRemove, postAbortable } from "../Services/http"
import { toggleSubProjectDrawer } from "./Drawer.action"
import { toggle as toggleCart, load as loadCart } from "./Cart.action"
import { catchError } from "./Error.action"

export const SUBPROJECT_RECEIVE = "SUBPROJECT_RECEIVE"
export const SUBPROJECT_ERROR = "SUBPROJECT_ERROR"
export const SUBPROJECT_CHANGE_MODE = "SUBPROJECT_CHANGE_MODE"
export const SUBPROJECT_PRODUCT_SEARCH_QUERY = "SUBPROJECT_PRODUCT_SEARCH_QUERY"

const rootRoute = "/api/subproject"

export const changeMode = (mode) => ({
    type: SUBPROJECT_CHANGE_MODE,
    payload: {
        mode,
    },
})

export const query = (id, mode = "list") => (dispatch) => {
    return get(`${rootRoute}/getall/${id}`)
        .then((response) => response.json())
        .then((result) => {
            dispatch(receive(result, mode))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}
export const queryOne = (id, mode = "details") => (dispatch) => {
    return get(`${rootRoute}/${id}`)
        .then((response) => response.json())
        .then((result) => {
            dispatch(receiveOne(result, mode))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}
export const remove = (subproject) => (dispatch) =>
    httpRemove(`${rootRoute}/delete/${subproject.id}`)
        .then(() => dispatch(query(subproject.projectId)))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const add = (subproject) => (dispatch) =>
    post(`${rootRoute}/create`, subproject)
        .then(() => dispatch(query(subproject.projectId)))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const duplicate = (subproject) => (dispatch) =>
    post(`${rootRoute}/duplicate/${subproject.id}`)
        .then(() => dispatch(query(subproject.projectId)))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const edit = (subproject) => (dispatch) =>
    put(`${rootRoute}/update/${subproject.id}`, subproject)
        .then(() => dispatch(queryOne(subproject.id, "details")))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const copySubProjectToCart = ({ subProjectId, mode = "details" }) => {
    if (subProjectId) {
        return (dispatch) => {
            return post(`${rootRoute}/copySubProjectToCart`, subProjectId)
                .then(() => dispatch(queryOne(subProjectId, mode)))
                .then(() => dispatch(loadCart()))
                .then(() => dispatch(toggleCart()))
                .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
        }
    }
}

export const addItemToExistingList = (subprojectId, item, isDrawer = false) => (dispatch) =>
    post(`${rootRoute}/${subprojectId}/items/create`, item)
        .then(() => {
            if (isDrawer) {
                dispatch(toggleSubProjectDrawer())
            } else {
                dispatch(queryOne(subprojectId, "details"))
            }
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const addItemToNewList = (list) => (dispatch) =>
    post(`${rootRoute}/createwithitem`, list)
        .then((response) => response.json())
        .then((result) => dispatch(queryOne(result.id, "details")))
        .then(() => dispatch(toggleSubProjectDrawer()))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const editItem = (subprojectId, item) => (dispatch) =>
    put(`${rootRoute}/${subprojectId}/items/update/${item.id}`, item)
        .then(() => dispatch(queryOne(subprojectId, "details")))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const removeItem = (subprojectId, itemId) => (dispatch) =>
    httpRemove(`${rootRoute}/${subprojectId}/items/delete/${itemId}`)
        .then(() => dispatch(queryOne(subprojectId, "details")))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const searchProduct = (q, signal) => (dispatch, getState) => {
    dispatch({
        type: SUBPROJECT_PRODUCT_SEARCH_QUERY,
        payload: {
            query: q,
        },
    })
    return postAbortable("/api/quickSearchCustomerPortal", q, signal)
        .then((response) => response.json())
        .then((result) => dispatch(setSearchResult(result)))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}

const receive = (list, mode) => ({
    type: SUBPROJECT_RECEIVE,
    payload: {
        list,
        mode,
    },
})

const receiveOne = (subProject, mode) => ({
    type: SUBPROJECT_RECEIVE,
    payload: {
        subProject,
        mode,
    },
})

export const setError = (error) => ({
    type: SUBPROJECT_ERROR,
    payload: {
        error,
    },
})

const setSearchResult = (searchResult) => ({
    type: SUBPROJECT_RECEIVE,
    payload: {
        searchResult,
    },
})
